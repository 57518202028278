import React from 'react';
import { graphql} from 'gatsby';
import Disclaimer from '../components/ExternalPage';

export const query = graphql`
    query{
        content: allGhostPage (
            filter: { 
                tags : { elemMatch : { name: { eq: "#disclaimer" }}}
            }
        ){
            nodes {
                title
                html
            }
        }
    }
`;

const DisclaimerPage = props => {
    let content = props.data.content.nodes[0].html;
    let title = props.data.content.nodes[0].title;
    return (
        <Disclaimer
            data={content}
            title={title}
        />
    )
}

export default DisclaimerPage;